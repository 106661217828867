import React, {Component, Fragment} from 'react';
import {Reveal, Timeline, Tween} from "react-gsap";

const FadeInLeft = ({children}) => (
    <Tween
        from={{opacity: 0, transform: 'translate3d(-100vw, 0, 0)'}}
        stagger={0.2}
        ease="back.out(1.4)"
    >
        {children}
    </Tween>
);

class Header extends Component {

    render() {

        if (this.props.data) {
            var occupation = this.props.data.occupation;
            var description = this.props.data.description;
            var city = this.props.data.address.city;
            var networks = this.props.data.social.map(function (network) {
                return <li key={network.name}><a href={network.url}><i className={network.className} /></a></li>
            })
        }

        return (
            <header id="home">

                <nav id="nav-wrap">

                    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                    <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

                    <ul id="nav" className="nav">
                        <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
                        <li><a className="smoothscroll" href="#about">About</a></li>
                        <li><a className="smoothscroll" href="#portfolio">My Projects</a></li>
                        <li><a className="smoothscroll" href="#resume">Resume</a></li>

                    </ul>

                </nav>

                <div className="row banner">
                    <div className="banner-text">
                        <Reveal trigger={<div />}>
                            <Timeline
                                target={
                                    <Fragment>
                                        <h1>👋</h1>
                                        <h1>Hey, I'm Jonathan&nbsp;Vo.</h1>
                                    </Fragment>
                                }
                            >
                                <Tween
                                    from={{opacity: 0, transform: 'translate3d(-100vw, 0, 0)'}}
                                    // stagger={0.2}
                                    ease="back.out(1.4)"
                                />
                                <Tween
                                    to={{rotation: 35}}
                                    target={0}
                                    duration={0.2}
                                    timeScale={5}
                                />
                                <Tween
                                    to={{rotation: 0}}
                                    target={0}
                                    duration={0.3}
                                />
                            </Timeline>
                        </Reveal>
                        <h3>I am a California based <span>Student & Full-Stack Web Developer</span>. {description}.</h3>
                        <hr/>
                        <ul className="social">
                            {networks}
                        </ul>
                    </div>

                </div>

                <p className="scrolldown">
                    <a className="smoothscroll" href="#about"><i className="icon-down-circle" /></a>
                </p>

            </header>
        );
    }
}

export default Header;
