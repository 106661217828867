import React, {Component, Fragment} from 'react';
import {ScrollTrigger, Reveal, Timeline, Tween} from "react-gsap";
import {useMediaQuery} from 'react-responsive';


export default function Portfolio(props) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width:  767px'});
    if (props.data) {
        var projects = props.data.projects.map(function (projects) {
            var projectImage = 'images/portfolio/' + projects.image;
            return <div key={projects.title} className="columns portfolio-item">
                <div className="item-wrap">
                    <a href={projects.url} title={projects.title}>
                        <img alt={projects.title} src={projectImage}/>
                        <div className="overlay">
                            <div className="portfolio-item-meta">
                                <h5>{projects.title}</h5>
                                <p>{projects.category}</p>
                            </div>
                        </div>
                        <div className="link-icon"><i className="fa fa-link"/></div>
                    </a>
                </div>
            </div>
        })
    }
    if (projects !== undefined) {
        return (
            <section id="portfolio">

                <div className="row">

                    <div className="twelve columns collapsed">

                        <h1>Check Out Some of My Works.</h1>
                        <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                            {projects.map((project) => {
                                return project
                            })}
                        </div>
                    </div>
                </div>
            </section>
        );
    } else {
        return (<div/>)
    }
}
