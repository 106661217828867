import React, { Component, useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import About from './About';
import Resume from './Resume';
import Testimonials from './Testimonials';
import Portfolio from './Portfolio';
import resumeData from '../resumeData.json';

function MainPage(props) {
    return (
        <div className="App">
            <Header data={resumeData.main}/>
            <About data={resumeData.main}/>
            <Portfolio data={resumeData.portfolio}/>
            <Resume data={resumeData.resume}/>
            <Testimonials data={resumeData.testimonials}/>
            <Footer data={resumeData.main}/>
        </div>
    )
}
export default MainPage;