import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

function Jaja() {
    const [lightIsOn, setLight] = useState(false);
    const [verified, setVerified] = useState(false);
    const [input, setInput] = useState({password: ''});
    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
    const SECOND_MS = 1000;

    useEffect(() => {
        const interval = setInterval(() => {
            if (verified) {
                checkLight();
            } else if(cookies["jaja"] == "verified") {
                setVerified(true);
            }
        }, SECOND_MS);
    
        return () => clearInterval(interval);
        
    }, [verified]);

    const inputsHandler = (e) =>{
        setInput( {[e.target.name]: e.target.value} )
    }

    const submitButton = () =>{
        var shortHash = require('short-hash');
        if (shortHash(input.password) == "797ba568") {
            setVerified(true);
            setCookie("jaja", "verified");
        } else {
            alert("Wrong passcode");
        }
    }

    const checkLight = () => {
        var config = {
            method: 'get',
            url: "https://us-central1-site-portfolio-c334e.cloudfunctions.net/server/isLightOn",
            headers: { }
        };
        // console.log("Checking...");
        require('axios')(config)
            .then(function (response) {
                setLight(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const turnLightOff = (endpoint) => {
        // console.log("Going...");
        var config = {
            method: 'get',
            url: endpoint,
            headers: { }
        };

        require('axios')(config)
            .then(function (response) {
                // console.log("Finished");
                // console.log(JSON.stringify(response.data));
                checkLight();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    if (verified) {
        return (
            <header>
                <div className="row banner">
                    <div className="banner-text">
                        <h1>Light Status:</h1>
                        {lightIsOn == 'true' && <h1>On</h1>}
                        {lightIsOn != 'true' && <h1>Off</h1>}
                    </div>
                    <button style={{padding: "8%", fontSize: "4em"}} onClick={() => turnLightOff("https://us-central1-site-portfolio-c334e.cloudfunctions.net/server/turnLightOff")}>Turn Off</button>
                    {/* <button onClick={() => turnLightOff("https://us-central1-site-portfolio-c334e.cloudfunctions.net/server/turnLightOff")}>Turn Off</button> */}
                    <button style={{padding: "8%", fontSize: "4em"}}onClick={() => turnLightOff("https://us-central1-site-portfolio-c334e.cloudfunctions.net/server/turnLightOn")}>Turn On</button>
                </div>
                
            </header>
        )
    } else {
        return (
            <header>
                <div className="row banner">
                    <div className="banner-text">
                        <h1>Login</h1>
                        <form onSubmit={submitButton}>
                            <input 
                                type="password" 
                                name="password" 
                                onChange={inputsHandler} 
                                // placeholder="First Name" 
                                value={input.password}
                                style={{width: "100%"}}/>
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
                
            </header>
        )
    }
}

export default Jaja;