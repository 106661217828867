import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './Components/MainPage';
import Jaja from './Components/Jaja';
import { CookiesProvider } from 'react-cookie';


function App(props) {
  var [foo, setFoo] = useState('bar');

  return (
    <Router>
        <Routes>
          <Route path="/" exact element={<MainPage />}/>
          <Route path="/jaja" element={<CookiesProvider><Jaja /></CookiesProvider>} />
        </Routes>
      </Router>
  )
}

// class App extends Component {

//   constructor(props){
//     super(props);
//     this.state = {
//       foo: 'bar',
//       resumeData: {}
//     };
//   }

//   getResumeData(){
//     $.ajax({
//       url:'/resumeData.json',
//       dataType:'json',
//       cache: false,
//       success: function(data){
//         this.setState({resumeData: data});
//       }.bind(this),
//       error: function(xhr, status, err){
//         console.log(err);
//         alert(err);
//       }
//     });
//   }

//   componentDidMount(){
//     this.getResumeData();
//   }

//   render() {
//     return (
//       <div className="App">
//         <Header data={this.state.resumeData.main}/>
//         <About data={this.state.resumeData.main}/>
//         <Portfolio data={this.state.resumeData.portfolio}/>
//         <Resume data={this.state.resumeData.resume}/>
//         <Testimonials data={this.state.resumeData.testimonials}/>
//         <Footer data={this.state.resumeData.main}/>
//       </div>
//     );
//   }
// }

export default App;
